body {
    font-family: 'Poppins', sans-serif;

}
#content {
    font-family: 'Poppins', sans-serif;
    /* background: #ebe9e9; */
    background-image: url('Background.png');
    background-size: cover;
    
}
p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}
/*------------------------
ICON SIZE
--------------------------*/
td img {
    width:20px;
}
li img {
    width:15px;
}
/*-------------------------

CARDS

-------------------------*/
.card {
    border-radius:10px;
    margin-bottom:30px;
}
.card-link {
   float:right;
}
.card-title {

    font-weight:500;
}
.card-subtitle {

    font-size:0.8em;

}
.card-text {
    font-size: 0.8em;
  
}
.card-link {
    font-size: 0.8em;
    
}
.card-flat-bg-purple {
    background-color: #8da3cd;
}
.card-flat-bg-purple .card-title {
    color:white;
}
.card-flat-bg-purple .card-subtitle {
    color: white;
}
.card-flat-bg-purple .card-text {
    color:white;
    margin-bottom:80px;
}
.card-flat-bg-purple .card-link {
    color: white;
}
.card-flat-bg-green {
    background-color:#78bea9;
}
.card-flat-bg-green .card-title {
    color:white;
}
.card-flat-bg-green .card-subtitle {
    color: white;
}
.card-flat-bg-green .card-text {
    color:white;
    margin-bottom:80px;
}
.card-flat-bg-green .card-link {
    color: white;
}
/*-------------------------

PROGRESS BAR

-------------------------*/
.custom-circular-bar .circular-bar-chart {
    position: relative;
    overflow: hidden;
    height: 175px;
}
.custom-circular-bar .circular-bar-chart-2 {
    position: relative;
    overflow: hidden;
    height: 175px;
}
.circular-bar {
    text-align: center;
   
}
.circular-bar strong {
    display: block;
    font-weight: normal;
    
    line-height: 30px;
    position: absolute;
    color:white;
    width: 80%;
    left: 10%;
    text-align: center;
    top: 49%;
    font-size: 46px !important;
    transform: translateY(-50%);
}
/*-------------------------

Custom Button

-------------------------*/
.custom-primary-btn {
    color: #fff;
    background-color: #4cbf9f;
    border-color: #4cbf9f;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    margin-top: 1rem !important;
}
.custom-danger-btn {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    margin-top: 1rem !important;
}
.custom-success-btn {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    margin-top: 1rem !important;
}
/*-------------------------

CONTENT STYLING

-------------------------*/
.title-text {
    font-weight: 500;
    font-size:1.1em;
    margin-bottom:20px;
}
.title-text {
    font-weight: normal;
    font-size:1.1em;
     
}
p {
    font-size: 0.9em;
    color:black;
}
/*-------------------------

BUTTONS

-------------------------*/
.flag:disabled {

    color: white;
    padding: 5px 5px;
    border-radius: 10px;
    font-size: 0.9em;
    opacity: 1;
}
.link:hover {
    cursor: pointer;
}
/*-------------------------

Tooltip

-------------------------*/
.custom-tooltip {

    cursor: pointer;
    font-size: 16px;
    white-space: pre-line;
     
    
}
.help-text:hover{
     text-decoration: none;
 }
.tooltip-text {
    cursor: pointer;
    font-size: 12px;
    color:black;
}
/*-------------------------

TABLES

-------------------------*/
.table {
    background: white;
    border-radius: 10px;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #fff0;
}
.table td, .table th  {
    padding: .50rem;
    vertical-align: top;
    border-top: 1px solid #fff0;
}
.table th, .mat-header-cell {
    text-align: left;
    color: inherit;
    font-size: inherit;
    font-weight: bold;
}
.table td, .mat-table td {
    font-size: 0.9em;
    color: black;
    text-align:left;
}
.mat-table {
    font-family: 'Poppins', sans-serif;
}
.mat-header-cell {
    text-align:left;
}
.mat-card {
    border-radius: 10px !important;
    box-shadow: none !important;
}
td.mat-cell {
    border-bottom-style: hidden !important;
}
.filter-input {
    float: right;
}
@media 
only screen and (max-width:800px){
    .table-card {
        overflow: scroll;
    }
}